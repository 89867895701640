<template>
  <Pop class="updateSW" @onSubmit="submitPop">
    <template v-slot:header>
      <div class="updateSW__container">
        <img class="updateSW__icon" alt="MySpaceBrand" src="../../assets/img/MySpaceBrand.svg" />
        <h4 class="updateSW__subtitle">{{ popMsg.title }}</h4>
      </div>
    </template>
    <template v-slot:body>
      <p class="updateSW__text">
        {{ popMsg.msg }}
      </p>
    </template>
    <template v-slot:footer>
      <button class="updateSW__btn" @click="submitPop">
        Actualizar
      </button>
    </template>
  </Pop>
</template>

<script>
  export default {
    components: { Pop: () => import("@/components/modals/Modal.vue") },
    data: function() {
      return {
        popMsg: {
          title: "Actualización disponible",
          msg: "El contenido de esta aplicación ha sido actualizado, por favor, haga clic en actualizar ó manualmente actualice el navegador, para aplilcar los cambios.",
        },
      };
    },
    methods: {
      submitPop() {
        window.location.reload();
        document.querySelector(".filterBlur")?.classList?.remove("blur");
        this.$store.state.hasUpdateSW = false;
      },
    },
  };
</script>

<style lang="scss">
  .updateSW {
    .modal-g__container {
      padding: 0;
      gap: $mpadding;
      max-width: $mobile_small_width;
      border-radius: $mpadding;
      max-width: 320px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    }
    &__container {
      @include Column();
      margin-top: 12px;
    }
    &__subtitle {
      font-size: 18px;
      color: #272727;
      font-family: $newFont_semiBold;
      text-align: center;
      text-transform: none;
    }
    &__text {
      font-size: 14px;
      font-family: $newFont_regular;
      color: #272727;
      text-align: center;
      line-height: normal;
      padding: 0 $mpadding * 2 0 $mpadding * 2;
    }
    &__icon {
      margin: $space-20 0;
    }
    &__btn {
      width: 100%;
      padding: $mpadding;
      appearance: none;
      border: none;
      outline: none;
      font-family: $newFont_semiBold;
      color: #272727;
      border-top: solid 1px $alto;
      background-color: $white;
      cursor: pointer;
      &:active,
      &:hover {
        background: $primary_color;
        color: $white;
      }
    }
  }
</style>
